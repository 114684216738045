import {
    DeleteForever,
    FilterCenterFocus,
    Flip,
    Height,
    KeyboardArrowDown,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardArrowUp,
    Mode,
    ScreenRotationOutlined,
    SettingsBackupRestoreOutlined,
    UndoOutlined,
    ZoomIn,
    ZoomOut,
} from '@mui/icons-material';
import { Slider } from '@mui/material';
import { SettingItemProps, sides } from '../utils/Types';

export const SizeSlider = ({ value, setValue }: SettingItemProps<number>) => {
    return (
        <div className="absolute top-5 left-5 h-10 w-64 flex flex-row justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl">
            <div
                className="bg-black h-10 w-20 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full mr-2.5 flex justify-center items-center cursor-pointer"
                onClick={() => {
                    if (value >= 85) {
                        setValue(value - 10);
                    }
                }}
            >
                <ZoomOut className="text-white" />
            </div>
            <Slider
                aria-label="Size"
                value={value}
                min={75}
                max={300}
                sx={{
                    color: 'white',
                }}
                onChange={(event, value) => setValue(value as number)}
            />
            <div
                className="bg-black h-10 w-20 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full ml-2.5 flex justify-center items-center cursor-pointer"
                onClick={() => {
                    if (value <= 390) {
                        setValue(value + 10);
                    }
                }}
            >
                <ZoomIn className="text-white" />
            </div>
        </div>
    );
};

export const HorizontalSlider = ({
    value,
    setValue,
}: SettingItemProps<number>) => {
    return (
        <div className="absolute bottom-5 left-5 h-10 w-64 flex flex-row justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl">
            <div
                className="bg-black h-10 w-20 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full mr-2.5 flex justify-center items-center cursor-pointer"
                onClick={() => {
                    if (value >= -45) {
                        setValue(value - 5);
                    }
                }}
            >
                <KeyboardArrowLeft className="text-white" />
            </div>
            <Slider
                aria-label="Size"
                value={value}
                min={-50}
                max={+50}
                sx={{
                    color: 'white',
                }}
                onChange={(event, value) => setValue(value as number)}
            />
            <div
                className="bg-black h-10 w-20 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full ml-2.5 flex justify-center items-center cursor-pointer"
                onClick={() => {
                    if (value <= 45) {
                        setValue(value + 5);
                    }
                }}
            >
                <KeyboardArrowRight className="text-white" />
            </div>
        </div>
    );
};

export const VerticalSlider = ({
    value,
    setValue,
}: SettingItemProps<number>) => {
    return (
        <div className="absolute bottom-20 left-5 h-64 w-10 flex flex-col justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl">
            <div
                className="bg-black h-20 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full mb-2.5 flex justify-center items-center cursor-pointer"
                onClick={() => {
                    if (value <= 45) {
                        setValue(value + 5);
                    }
                }}
            >
                <KeyboardArrowUp className="text-white" />
            </div>
            <Slider
                aria-label="Size"
                orientation="vertical"
                value={value}
                min={-50}
                max={+50}
                sx={{
                    color: 'white',
                    WebkitAppearance: 'slider-vertical',
                }}
                onChange={(event, value) => setValue(value as number)}
            />
            <div
                className="bg-black h-20 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full mt-2.5 flex justify-center items-center cursor-pointer"
                onClick={() => {
                    if (value >= -45) {
                        setValue(value - 5);
                    }
                }}
            >
                <KeyboardArrowDown className="text-white" />
            </div>
        </div>
    );
};

export const RecenterButton = ({
    setHorizontalOffset,
    setVerticalOffset,
}: {
  setHorizontalOffset: (value: number) => void
  setVerticalOffset: (value: number) => void
}) => {
    return (
        <div
            className="absolute bottom-20 left-20 flex flex-row justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl"
            onClick={() => {
                setHorizontalOffset(0);
                setVerticalOffset(0);
            }}
        >
            <div className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full flex justify-center items-center text-white text-center font-semibold cursor-pointer">
                <FilterCenterFocus className="text-white" />
            </div>
        </div>
    );
};

export const FrontRotationActiveButton = ({
    active,
    setActive,
}: {
  active: boolean
  setActive: (side: boolean) => void
}) => {
    return (
        <div
            className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full flex justify-center items-center cursor-pointer"
            onClick={() => setActive(!active)}
        >
            <ScreenRotationOutlined
                style={{ color: active ? '#00CA43' : 'white' }}
            />
        </div>
    );
};

export const ResetAngleButton = ({
    onClickAction,
}: {
  onClickAction: () => void,
}) => {
    return (
        <div
            className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full flex justify-center items-center cursor-pointer"
            onClick={() => {
                onClickAction();
            }}
        >
            <SettingsBackupRestoreOutlined className='text-white'/>
        </div>
    );
};

export const FlipSideButton = ({
    side,
    setSide,
}: {
  side: number
  setSide: (side: number) => void
}) => {
    return (
        <div
            className="absolute top-5 right-5 flex flex-row justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl"
            onClick={() => {
                if (side === sides.front) {
                    setSide(sides.back);
                } else {
                    setSide(sides.front);
                }
            }}
        >
            <div className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full flex justify-center items-center text-white text-center font-semibold cursor-pointer">
                <Flip className="text-white" />
            </div>
        </div>
    );
};

export const MoveCardButton = ({
    active,
    setActive,
    otherOnClickAction,
}: {
  active: boolean
  setActive: (side: boolean) => void
  otherOnClickAction: () => void
}) => {
    return (
        <div
            className="absolute bottom-5 right-5 flex flex-row justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl"
            onClick={() => {
                setActive(!active);
                otherOnClickAction();
            }}
        >
            <div
                className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-2 border-white rounded-full flex justify-center items-center text-white text-center font-semibold cursor-pointer"
            >
                <Height
                    style={{ color: active ? '#00CA43' : 'white' }}
                />
            </div>
        </div>
    );
};

export const DrawingButton = ({
    active,
    setActive,
    otherOnClickAction,
}: {
  active: boolean
  setActive: (side: boolean) => void
  otherOnClickAction: () => void,
}) => {
    return (
        <div
            className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full flex justify-center items-center cursor-pointer"
            onClick={() => {
                setActive(!active);
                otherOnClickAction();
            }}
        >
            <Mode
                style={{ color: active ? '#00CA43' : 'white' }}
            />
        </div>
    );
};

export const ResetCanvasButton = ({
    active,
    setActive,
}: {
  active: boolean
  setActive: (side: boolean) => void
}) => {
    return (
        <div
            className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full flex justify-center items-center cursor-pointer"
            onClick={() => {
                setActive(!active);
            }}
        >
            <DeleteForever className='text-white'/>
        </div>
    );
};

export const RevertButton = ({
    onClickAction,
}: {
  onClickAction: () => void,
}) => {
    return (
        <div
            className="bg-black h-10 w-10 bg-opacity-20 hover:bg-opacity-30 active:hover:bg-opacity-40 border-white border-2 rounded-full flex justify-center items-center cursor-pointer"
            onClick={() => {
                onClickAction();
            }}
        >
            <UndoOutlined className='text-white'/>
        </div>
    );
};

export const DrawingTools = ({
    drawingButtonActive,
    setDrawingButtonActive,
    resetCanvas,
    setResetCanvas,
    canvasEmpty,
    onClickCanvasUndo,
    otherOnClickAction,
}: {
    drawingButtonActive: boolean,
    setDrawingButtonActive: (value: boolean) => void,
    resetCanvas: boolean,
    setResetCanvas: (value: boolean) => void,
    canvasEmpty: boolean,
    onClickCanvasUndo: () => void,
    otherOnClickAction: () => void,
}) => {
    return (
        <div className="absolute bottom-20 right-5 flex flex-col gap-4 justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl">
            {((drawingButtonActive && !canvasEmpty) &&
                        <RevertButton
                            onClickAction={onClickCanvasUndo}
                        />
            )}
            {((drawingButtonActive && !canvasEmpty) &&
                        <ResetCanvasButton
                            active={resetCanvas}
                            setActive={setResetCanvas}
                        />
            )}
            <DrawingButton
                active={drawingButtonActive}
                setActive={setDrawingButtonActive}
                otherOnClickAction={otherOnClickAction}
            />
        </div>
    );
};

export const FrontRotationTools = ({
    frontRotationActive,
    setFrontRotationActive,
    frontRotationAngle,
    resetRotation,
}: {
    frontRotationActive: boolean,
    setFrontRotationActive: (value: boolean) => void,
    frontRotationAngle: number
    resetRotation: () => void,
}) => {
    return (
        <div className="absolute bottom-5 right-5 flex flex-col gap-4 justify-center items-center pointer-events-auto touch-auto bg-black bg-opacity-10 hover:bg-opacity-20 rounded-3xl">
            {((frontRotationActive && (frontRotationAngle !== 0)) &&
                <ResetAngleButton
                    onClickAction={resetRotation}
                />
            )}
            <FrontRotationActiveButton
                active={frontRotationActive}
                setActive={setFrontRotationActive}
            />
        </div>
    );
};
