import { useRef, useState } from 'react';
import crp5FrontFramePNG from '../assets/crp5-front-frame.png';
import crp5FrontDiskPNG from '../assets/crp5-front-disk.png';
import crp5FrontPointerPNG from '../assets/crp5-front-pointer.png';
import crp5FrontFrameSVG from '../assets/crp5-front-frame.svg';
import crp5FrontDiskSVG from '../assets/crp5-front-disk.svg';
import crp5FrontPointerSVG from '../assets/crp5-front-pointer.svg';
import { calculateAngle, isChrome, isChromium } from '../utils/Functions';

const crp5FrontPointerImage = (): string => {
    if (isChrome || isChromium) {
        return crp5FrontPointerPNG;
    }
    return crp5FrontPointerSVG;
};

const crp5FrontDiskImage = (): string => {
    if (isChrome || isChromium) {
        return crp5FrontDiskPNG;
    }
    return crp5FrontDiskSVG;
};

const crp5FrontFrameImage = (): string => {
    if (isChrome || isChromium) {
        return crp5FrontFramePNG;
    }
    return crp5FrontFrameSVG;
};

const Pointer = ({
    angle,
    setAngle,
    frontRotationActive,
    frontRotationAngle,
}: {
  angle: number
  setAngle: (angle: number) => void
  frontRotationActive: boolean
  frontRotationAngle: number
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const pointerRef = useRef<HTMLDivElement>(null);
    const [previousX, setPreviousX] = useState(0);
    const [previousY, setPreviousY] = useState(0);

    return (
        <div
            className={
                'col-span-full row-span-full h-full w-auto relative hover:origin-center'
            }
            ref={pointerRef}
            style={{ rotate: (angle + frontRotationAngle) + 'deg' }}
        >
            <div
                className="h-[25%] w-[12%] absolute left-1/2 top-[40%] -translate-y-1/2 -translate-x-1/2 cursor-move"
                style={{ pointerEvents: frontRotationActive ? 'none' : 'auto', touchAction: frontRotationActive ? 'none' : 'auto' }}
                onPointerDown={() => setIsDragging(true)}
                onPointerMove={(event) => {
                    if (!isDragging) {
                        return;
                    };
                    const element = pointerRef.current;
                    if (!element) {
                        return;
                    };
                    console.log(angle);
                    const { top, left, width, height } = element.getBoundingClientRect();
                    const centerX = left + width / 2;
                    const centerY = top + height / 2;
                    if (previousX & previousY) {
                        const diffAngle = calculateAngle(
                            { x: previousX, y: previousY },
                            { x: event.clientX, y: event.clientY },
                            { x: centerX, y: centerY },
                        );
                        console.log(diffAngle);
                        setAngle(angle + diffAngle);
                    }
                    setPreviousX(event.clientX);
                    setPreviousY(event.clientY);
                }}
                onPointerUp={() => {
                    setIsDragging(false);
                    setPreviousX(0);
                    setPreviousY(0);
                }}
            ></div>
            <div className="h-full w-full absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
                <img
                    src={crp5FrontPointerImage()}
                    className="h-full w-full object-contain pointer-events-none touch-none"
                    alt="crp5 front pointer"
                />
            </div>
        </div>
    );
};

const RotationInterceptor = ({
    angle,
    setAngle,
}: {
angle: number
setAngle: (angle: number) => void
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [previousX, setPreviousX] = useState(0);
    const [previousY, setPreviousY] = useState(0);
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div
            className={
                'rotation interceptor col-span-full row-span-full h-full w-full absolute'
            }
        >
            <div
                className="h-[100%] w-[55%] absolute left-1/2 top-[50%] -translate-y-1/2 -translate-x-1/2 select-none cursor-move touch-auto pointer-events-auto"
                ref={ref}
                onPointerDown={() => setIsDragging(true)}
                onPointerMove={(event) => {
                    if (!isDragging) {
                        return;
                    };
                    const element = ref.current;
                    if (!element) {
                        return;
                    };
                    const { top, left, width, height } = element.getBoundingClientRect();
                    const centerX = left + width / 2;
                    const centerY = top + height / 2;
                    if (previousX & previousY) {
                        const diffAngle = calculateAngle(
                            { x: previousX, y: previousY },
                            { x: event.clientX, y: event.clientY },
                            { x: centerX, y: centerY },
                        );
                        setAngle(angle + diffAngle);
                    }
                    setPreviousX(event.clientX);
                    setPreviousY(event.clientY);
                }}
                onPointerUp={() => {
                    setIsDragging(false);
                    setPreviousX(0);
                    setPreviousY(0);
                }}
            ></div>
        </div>
    );
};

const FrontDisk = ({
    angle,
    setAngle,
    frontRotationActive,
    frontRotationAngle,
}: {
  angle: number
  setAngle: (angle: number) => void
  frontRotationActive: boolean
  frontRotationAngle: number
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [previousX, setPreviousX] = useState(0);
    const [previousY, setPreviousY] = useState(0);

    return (
        <div
            className={
                'col-span-full row-span-full h-full w-auto relative hover:origin-center'
            }
            ref={ref}
            style={{ rotate: (angle + frontRotationAngle) + 'deg' }}
        >
            <div
                className="h-[35%] w-[50%] absolute left-1/2 top-[50%] -translate-y-1/2 -translate-x-1/2 cursor-move"
                style={{ pointerEvents: frontRotationActive ? 'none' : 'auto', touchAction: frontRotationActive ? 'none' : 'auto' }}
                onPointerDown={() => setIsDragging(true)}
                onPointerMove={(event) => {
                    if (!isDragging) {
                        return;
                    };
                    const element = ref.current;
                    if (!element) {
                        return;
                    };
                    const { top, left, width, height } = element.getBoundingClientRect();
                    const centerX = left + width / 2;
                    const centerY = top + height / 2;
                    if (previousX & previousY) {
                        const diffAngle = calculateAngle(
                            { x: previousX, y: previousY },
                            { x: event.clientX, y: event.clientY },
                            { x: centerX, y: centerY },
                        );
                        setAngle(angle + diffAngle);
                    }
                    setPreviousX(event.clientX);
                    setPreviousY(event.clientY);
                }}
                onPointerUp={() => {
                    setIsDragging(false);
                    setPreviousX(0);
                    setPreviousY(0);
                }}
            ></div>
            <div className="h-full w-full absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
                <img
                    src={crp5FrontDiskImage()}
                    className="h-full w-full object-contain pointer-events-none touch-none"
                    alt="crp5 front disk"
                />
            </div>
        </div>
    );
};

export const FrontSide = ({
    scale,
    verticalOffset,
    horizontalOffset,
    diskAngle,
    setDiskAngle,
    pointerAngle,
    setPointerAngle,
    frontRotationActive,
    frontRotationAngle,
    setFrontRotationAngle,
}: {
  scale: number
  verticalOffset: number
  horizontalOffset: number
  diskAngle: number
  setDiskAngle: (value: number) => void
  pointerAngle: number
  setPointerAngle: (value: number) => void
  frontRotationActive: boolean
  frontRotationAngle: number
  setFrontRotationAngle: (value: number) => void
}) => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div
                className="inline-grid items-center justify-center h-screen"
                style={{
                    scale: ((scale / 100) * 1.25).toString(),
                    translate: horizontalOffset * -1 + '%' + verticalOffset * 1 + '%',
                }}
            >
                <div className="col-span-full row-span-full h-full w-auto relative">
                    <img
                        src={crp5FrontFrameImage()}
                        className="h-full w-full max-w-[600px] object-contain cursor-none"
                        alt="crp5 front frame"
                        style={{
                            rotate: frontRotationAngle + 'deg',
                        }}
                    />
                </div>
                <div
                    className="h-[50%] w-[65%] absolute left-1/2 top-[50%] -translate-y-1/2 -translate-x-1/2 select-none touch-none pointer-events-none cursor-default"
                ></div>
                <FrontDisk angle={diskAngle} setAngle={setDiskAngle} frontRotationActive={frontRotationActive} frontRotationAngle={frontRotationAngle} />
                <Pointer angle={pointerAngle} setAngle={setPointerAngle} frontRotationActive={frontRotationActive} frontRotationAngle={frontRotationAngle} />
            </div>
            {(frontRotationActive) && (
                <RotationInterceptor angle={frontRotationAngle} setAngle={setFrontRotationAngle} />
            )}
        </div>
    );
};
