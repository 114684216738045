import { useEffect, useState } from 'react';
import './App.css';
import { BackSide } from './components/BackSide';
import { FrontSide } from './components/FrontSide';
import {
    DrawingTools,
    FlipSideButton,
    FrontRotationTools,
    HorizontalSlider,
    MoveCardButton,
    RecenterButton,
    SizeSlider,
    VerticalSlider,
} from './components/Overlay';
import { BeforeInstallPromptEvent, sides } from './utils/Types';

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent
  }
}

function App() {
    const [side, setSide] = useState(sides.front);
    const [scale, setScale] = useState(100);
    const [verticalOffset, setVerticalOffset] = useState(0);
    const [horizontalOffset, setHorizontalOffset] = useState(0);
    const isOffcenter = () => {
        return verticalOffset !== 0 || horizontalOffset !== 0;
    };
    const [moveButtonActive, setMoveButtonActive] = useState(false);
    const [drawingButtonActive, setDrawingButtonActive] = useState(false);
    const [frontRotationActive, setFrontRotationActive] = useState(false);

    const [frontRotationAngle, setFrontRotationAngle] = useState(0);
    const [frontDiskAngle, setFrontDiskAngle] = useState(0);
    const [frontPointerAngle, setFrontPointerAngle] = useState(0);

    const [backDiskAngle, setBackDiskAngle] = useState(0);
    const [cardPosition, setCardPosition] = useState(0);
    const [resetCanvas, setResetCanvas] = useState(false);
    const [canvasUndo, setCanvasUndo] = useState(false);
    const [canvasHistory, setCanvasHistory] = useState({});

    const handleScroll = (deltaY: number) => {
        let newScale = scale - deltaY / 4;
        if (newScale > 300) {
            newScale = 300;
        };
        if (newScale < 75) {
            newScale = 75;
        };
        setScale(newScale);
    };

    // workaround to fix initial wrong vh
    useEffect(() => {
        window.dispatchEvent(new CustomEvent('scroll'));
    }, []);

    useEffect(() => {
        setScale(100);
        setVerticalOffset(0);
        setHorizontalOffset(0);
        setMoveButtonActive(false);
        setDrawingButtonActive(false);
    }, [side]);

    return (
        <div
            className="bg-blue-500 h-screen w-screen touch-none overflow-hidden pointer-events-none select-none"
            style={{ WebkitTapHighlightColor: 'transparent' }}
            onWheel={(e) => handleScroll(e.deltaY)}
        >
            {side === sides.front && (
                <FrontSide
                    scale={scale}
                    verticalOffset={verticalOffset}
                    horizontalOffset={horizontalOffset}
                    diskAngle={frontDiskAngle}
                    setDiskAngle={setFrontDiskAngle}
                    pointerAngle={frontPointerAngle}
                    setPointerAngle={setFrontPointerAngle}
                    frontRotationActive={frontRotationActive}
                    frontRotationAngle={frontRotationAngle}
                    setFrontRotationAngle={setFrontRotationAngle}
                />
            )}
            {side === sides.back && (
                <BackSide
                    scale={scale}
                    verticalOffset={verticalOffset}
                    horizontalOffset={horizontalOffset}
                    diskAngle={backDiskAngle}
                    setDiskAngle={setBackDiskAngle}
                    cardPosition={cardPosition}
                    setCardPosition={setCardPosition}
                    moveButtonActive={moveButtonActive}
                    drawingButtonActive={drawingButtonActive}
                    canvasHistory={canvasHistory}
                    setCanvasHistory={setCanvasHistory}
                    canvasUndo={canvasUndo}
                    setCanvasUndo={setCanvasUndo}
                    resetCanvas={resetCanvas}
                    setResetCanvas={setResetCanvas}
                />
            )}
            <SizeSlider value={scale} setValue={setScale} />
            <VerticalSlider value={verticalOffset} setValue={setVerticalOffset} />
            <HorizontalSlider
                value={horizontalOffset}
                setValue={setHorizontalOffset}
            />
            {isOffcenter() && (
                <RecenterButton
                    setHorizontalOffset={setHorizontalOffset}
                    setVerticalOffset={setVerticalOffset}
                />
            )}
            <FlipSideButton side={side} setSide={setSide} />
            {side === sides.front && (
                <div>
                    <FrontRotationTools
                        frontRotationActive={frontRotationActive}
                        setFrontRotationActive={setFrontRotationActive}
                        frontRotationAngle={frontRotationAngle}
                        resetRotation={() => setFrontRotationAngle(0)}
                    />
                </div>
            )}
            {side === sides.back && (
                <div>
                    <MoveCardButton
                        active={(moveButtonActive)}
                        setActive={setMoveButtonActive}
                        otherOnClickAction={() => {
                            setDrawingButtonActive(false);
                        }}
                    />
                    <DrawingTools
                        drawingButtonActive={drawingButtonActive}
                        setDrawingButtonActive={setDrawingButtonActive}
                        resetCanvas={resetCanvas}
                        setResetCanvas={setResetCanvas}
                        canvasEmpty={(() => {
                            return Object.keys(canvasHistory).length === 0;
                        })()}
                        onClickCanvasUndo={() => setCanvasUndo(true)}
                        otherOnClickAction={() => {
                            setMoveButtonActive(false);
                        }}

                    />
                </div>
            )}
        </div>
    );
}

export default App;
