export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed'
    platform: string
  }>
  prompt(): Promise<void>
}

export enum sides {
  front,
  back,
  low,
  high
}

export interface point {
  x: number
  y: number
}

export interface SettingItemProps<T> {
  value: T
  setValue: (value: T) => void
}

export interface canvasHistoryType {
    [key: string | number]: {
      x: number;
      y: number;
    }[];
}
