import { point } from './Types';

export const calculateAngle = (A: point, B: point, C: point): number => {
    // calculate the distances from the center to the two points
    const CA = Math.sqrt((A.x - C.x) ** 2 + (A.y - C.y) ** 2);
    const CB = Math.sqrt((B.x - C.x) ** 2 + (B.y - C.y) ** 2);

    // calculate the distance between the two points
    const AB = Math.sqrt((B.x - A.x) ** 2 + (B.y - A.y) ** 2);

    // calculate the cosine of the angle between the three points using the Law of Cosines
    const cosAngle = (CA ** 2 + CB ** 2 - AB ** 2) / (2 * CA * CB);

    // calculate the angle in radians
    let angleRadians = Math.acos(cosAngle);

    const crossProduct = (A.x - C.x) * (B.y - C.y) - (B.x - C.x) * (A.y - C.y);
    if (crossProduct < 0) {
        angleRadians = -angleRadians;
    }

    // convert the angle from radians to degrees
    const angleDegrees = (angleRadians * 180) / Math.PI;

    return angleDegrees;
};

export const rotatePoint = (x: number, y: number, centerX: number, centerY: number, angleInDegrees: number): [newX: number, newY: number] => {
    const angleInRadians = (angleInDegrees) * (-1) * Math.PI / 180;

    const newX = Math.cos(angleInRadians) * (x - centerX) - Math.sin(angleInRadians) * (y - centerY) + centerX;
    const newY = Math.sin(angleInRadians) * (x - centerX) + Math.cos(angleInRadians) * (y - centerY) + centerY;

    return [newX, newY];
};

export const rotateLengths = (oldWidth: number, oldHeight: number, rotationAngle: number) => {

    while (rotationAngle < 0) {
        rotationAngle += 360;
    }
    rotationAngle = rotationAngle % 90;

    const newWidth = oldWidth * 1 / (Math.sin(rotationAngle * Math.PI / 180) + Math.cos(rotationAngle * Math.PI / 180));
    const newHeight = oldHeight * 1 / (Math.sin(rotationAngle * Math.PI / 180) + Math.cos(rotationAngle * Math.PI / 180));

    return [newWidth, newHeight];
};

// @ts-ignore
export const isChromium = (!!navigator.userAgentData && navigator.userAgentData.brands.some(data => data.brand == 'Chromium'));
// @ts-ignore
export const isChrome = (!!window.chrome);
