import { useEffect, useRef, useState } from "react";
import { rotateLengths, rotatePoint } from "../utils/Functions";
import { canvasHistoryType } from "../utils/Types";

export const Canvas = ({
    drawingButtonActive,
    diskAngle,
    canvasHistory,
    setCanvasHistory,
    canvasUndo,
    setCanvasUndo,
    resetCanvas,
    setResetCanvas,
}: {
    drawingButtonActive: boolean,
    diskAngle: number,
    canvasHistory: canvasHistoryType
    setCanvasHistory: (canvasHistory: object) => void
    canvasUndo: boolean,
    setCanvasUndo: (value: boolean) => void,
    resetCanvas: boolean,
    setResetCanvas: (value: boolean) => void,
}) => {

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const canvasWipRef = useRef<HTMLCanvasElement>(null);
    const contextRef = useRef<CanvasRenderingContext2D | null>(null);
    const contextWipRef = useRef<CanvasRenderingContext2D | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [isDrawing, setIsDrawing] = useState(false);
    const [xStart, setXStart] = useState(0);
    const [yStart, setYStart] = useState(0);

    const handleResetCanvas = () => {
        canvasRef?.current?.getContext("2d")?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.width);
        for (const key in canvasHistory) {
            delete canvasHistory[key];
        }
        setResetCanvas(false);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const canvasWip = canvasWipRef.current;
        if (canvas && canvasWip) {
            const context = canvas.getContext("2d");
            const contextWip = canvasWip.getContext("2d");
            if (context && contextWip) {
                context.lineCap = 'round';
                context.strokeStyle = 'red';
                contextRef.current = context;
                contextWipRef.current = contextWip;
            }
        }
    }, []);

    useEffect(() => {
        if (resetCanvas) {
            handleResetCanvas();
        }
    }, [resetCanvas]);

    // redraw on component re-mount
    useEffect(() => {
        if (Object.keys(canvasHistory).length > 0) {
            if (contextRef.current) {
                const previousSteps = Object.keys(canvasHistory).length ? Object.keys(canvasHistory).length : 0;
                const temp: canvasHistoryType = {
                    ...canvasHistory,
                    [previousSteps + 1]: [],
                };
                temp[previousSteps + 1].push({ x: 0, y: 0 });
                setCanvasHistory(temp);
                setCanvasUndo(true);
            }
        }
    }, []);

    useEffect(() => {
        if (canvasUndo) {
            const lastStep = Object.keys(canvasHistory).length;
            delete canvasHistory[lastStep];
            redraw();
            setCanvasUndo(false);
        }
    }, [canvasUndo]);

    const redraw = () => {
        canvasRef?.current?.getContext("2d")?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.width);
        if (!contextRef.current) {
            return;
        }
        contextRef.current.lineCap = 'round';
        contextRef.current.strokeStyle = 'red';
        contextRef.current.fillStyle = 'red';
        for (const stroke in canvasHistory) {
            console.log(canvasHistory[stroke]);
            const data = canvasHistory[stroke];
            // only a dot
            if (data.length === 1) {
                contextRef.current.fillRect(data[0].x - 1, data[0].y - 1, 2, 2);
            } else {
            // line
                contextRef.current.beginPath();
                contextRef.current.moveTo(data[0].x, data[0].y);
                contextRef.current.lineTo(data[1].x, data[1].y);
                contextRef.current.stroke();
                contextRef.current.closePath();
            }

        }
    };

    const startDrawing = ({ nativeEvent } : {nativeEvent: PointerEvent}) => {
        if (!drawingButtonActive) {
            return;
        }
        const { clientX, clientY } = nativeEvent;
        if (contextRef.current && canvasRef.current && containerRef.current && contextWipRef.current) {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const middleX = (canvasRect.left + canvasRect.right) / 2;
            const middleY = (canvasRect.top + canvasRect.bottom) / 2;
            const [rotatedWidth, rotatedHeight] = rotateLengths(canvasRect.width, canvasRect.height, diskAngle);
            const borderLeft = middleX - (canvasRef.current.width / 2) * 1 / (canvasRef.current.width / rotatedWidth);
            const borderTop = middleY - (canvasRef.current.width / 2) * 1 / (canvasRef.current.height / rotatedHeight);
            const x = (clientX - borderLeft) * (canvasRef.current.width / rotatedWidth);
            const y = (clientY - borderTop) * (canvasRef.current.height / rotatedHeight);
            const canvasCenterX = (middleX - borderLeft) * (canvasRef.current.width / rotatedWidth);
            const canvasCenterY = (middleY - borderTop) * (canvasRef.current.height / rotatedHeight);

            // workaround for android first stroke after deletion disappears
            if (!Object.keys(canvasHistory).length) {
                contextRef.current.fillStyle = 'white';
                contextRef.current.fillRect(canvasCenterX / 5, canvasCenterY / 5, 1, 1);
            }

            const [rotatedX, rotatedY] = rotatePoint(x, y, canvasCenterX, canvasCenterY, diskAngle);
            setXStart(rotatedX);
            setYStart(rotatedY);
            contextRef.current.lineCap = 'round';
            contextRef.current.strokeStyle = 'red';
            contextRef.current.beginPath();
            contextRef.current.moveTo(rotatedX, rotatedY);
            contextWipRef.current.lineCap = 'round';
            contextWipRef.current.strokeStyle = 'red';
            contextWipRef.current.beginPath();
            contextWipRef.current.moveTo(rotatedX, rotatedY);

            const previousSteps = Object.keys(canvasHistory).length ? Object.keys(canvasHistory).length : 0;

            const temp : canvasHistoryType = {
                ...canvasHistory,
                [previousSteps + 1]: [],
            };
            temp[previousSteps + 1].push({ x: rotatedX, y: rotatedY });
            setCanvasHistory(temp);
            setIsDrawing(true);
        }
    };

    const draw = ({ nativeEvent } : {nativeEvent: PointerEvent}) => {
        if (!isDrawing) {
            return;
        }
        const { clientX, clientY } = nativeEvent;
        if (contextWipRef.current && canvasRef.current && containerRef.current) {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const middleX = (canvasRect.left + canvasRect.right) / 2;
            const middleY = (canvasRect.top + canvasRect.bottom) / 2;
            const [rotatedWidth, rotatedHeight] = rotateLengths(canvasRect.width, canvasRect.height, diskAngle);
            const borderLeft = middleX - (canvasRef.current.width / 2) * 1 / (canvasRef.current.width / rotatedWidth);
            const borderTop = middleY - (canvasRef.current.width / 2) * 1 / (canvasRef.current.height / rotatedHeight);
            const x = (clientX - borderLeft) * (canvasRef.current.width / rotatedWidth);
            const y = (clientY - borderTop) * (canvasRef.current.height / rotatedHeight);
            const canvasCenterX = (middleX - borderLeft) * (canvasRef.current.width / rotatedWidth);
            const canvasCenterY = (middleY - borderTop) * (canvasRef.current.height / rotatedHeight);

            const [rotatedX, rotatedY] = rotatePoint(x, y, canvasCenterX, canvasCenterY, diskAngle);
            contextWipRef.current.lineTo(rotatedX, rotatedY);
            contextWipRef.current.stroke();
        }
    };

    const stopDrawing = ({ nativeEvent } : {nativeEvent: PointerEvent}) => {
        const { clientX, clientY } = nativeEvent;
        if (!isDrawing) {
            return;
        }
        if (contextRef.current && canvasRef.current && containerRef.current && contextWipRef.current) {
            const canvasRect = canvasRef.current.getBoundingClientRect();
            const middleX = (canvasRect.left + canvasRect.right) / 2;
            const middleY = (canvasRect.top + canvasRect.bottom) / 2;
            const [rotatedWidth, rotatedHeight] = rotateLengths(canvasRect.width, canvasRect.height, diskAngle);
            const borderLeft = middleX - (canvasRef.current.width / 2) * 1 / (canvasRef.current.width / rotatedWidth);
            const borderTop = middleY - (canvasRef.current.width / 2) * 1 / (canvasRef.current.height / rotatedHeight);
            const x = (clientX - borderLeft) * (canvasRef.current.width / rotatedWidth);
            const y = (clientY - borderTop) * (canvasRef.current.height / rotatedHeight);
            const canvasCenterX = (middleX - borderLeft) * (canvasRef.current.width / rotatedWidth);
            const canvasCenterY = (middleY - borderTop) * (canvasRef.current.height / rotatedHeight);

            const [rotatedX, rotatedY] = rotatePoint(x, y, canvasCenterX, canvasCenterY, diskAngle);

            if (!(Math.abs(rotatedY - yStart) > 2) && !(Math.abs(rotatedX - xStart) > 2)) {
                contextRef.current.fillStyle = 'red';
                contextRef.current.fillRect(xStart - 1, yStart - 1, 2, 2);

                contextRef.current.closePath();
                contextWipRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.width);
                setIsDrawing(false);
            } else {
                contextRef.current.lineTo(rotatedX, rotatedY);
                contextRef.current.stroke();
                contextRef.current.closePath();
                contextWipRef.current.closePath();

                const currentStep = Object.keys(canvasHistory).length ? Object.keys(canvasHistory).length : 0;
                const temp = {
                    ...canvasHistory,
                };
                temp[currentStep].push({ x: rotatedX, y: rotatedY });
            }
            contextWipRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.width);
            setIsDrawing(false);
        }
    };

    return (
        <div
            className='max-h-[36%] max-w-[51%] aspect-square relative left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 pointer-events-auto cursor-pointer touch-auto'
            ref={containerRef}
        >
            <canvas
                className="absolute top-0 left-0 w-full h-full"
                ref={canvasWipRef}
                width={containerRef.current?.offsetWidth}
                height={containerRef.current?.offsetHeight}
            />
            <canvas
                className="absolute top-0 left-0 w-full h-full"
                ref={canvasRef}
                width={containerRef.current?.offsetWidth}
                height={containerRef.current?.offsetHeight}
                onPointerDown={startDrawing}
                onPointerMove={draw}
                onPointerUp={stopDrawing}
            />
        </div>
    );
};
