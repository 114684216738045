import { useRef, useState } from 'react';

import crp5RearFramePNG from '../assets/crp5-rear-frame.png';
import crp5RearDiskPNG from '../assets/crp5-rear-disk.png';
import crp5SpeedScaleLowPNG from '../assets/crp5-speedscale-low.png';
import crp5SpeedScaleHighPNG from '../assets/crp5-speedscale-high.png';
import crp5RearFrameSVG from '../assets/crp5-rear-frame.svg';
import crp5RearDiskSVG from '../assets/crp5-rear-disk.svg';
import crp5SpeedScaleLowSVG from '../assets/crp5-speedscale-low.svg';
import crp5SpeedScaleHighSVG from '../assets/crp5-speedscale-high.svg';
import { calculateAngle, isChromium } from '../utils/Functions';
import { canvasHistoryType, sides } from '../utils/Types';
import { Canvas } from './Canvas';

const crp5RearFrameImage = (): string => {
    if (isChromium) {
        return crp5RearFramePNG;
    }
    return crp5RearFrameSVG;
};

const crp5RearDiskImage = (): string => {
    if (isChromium) {
        return crp5RearDiskPNG;
    }
    return crp5RearDiskSVG;
};

const crp5SpeedScaleLowImage = (): string => {
    if (isChromium) {
        return crp5SpeedScaleLowPNG;
    }
    return crp5SpeedScaleLowSVG;
};

const crp5SpeedScaleHighImage = (): string => {
    if (isChromium) {
        return crp5SpeedScaleHighPNG;
    }
    return crp5SpeedScaleHighSVG;
};

const Disk = ({
    angle,
    setAngle,
    drawingButtonActive,
}: {
  angle: number
  setAngle: (angle: number) => void
  drawingButtonActive: boolean
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const [previousX, setPreviousX] = useState(0);
    const [previousY, setPreviousY] = useState(0);

    return (
        <div
            className={
                'col-span-full row-span-full h-full w-auto relative hover:origin-center'
            }
            ref={ref}
            style={{ rotate: angle + 'deg' }}
        >
            <div
                className=" max-h-[45%] max-w-[60%] aspect-square relative left-1/2 top-[50%] -translate-y-1/2 -translate-x-1/2 cursor-move"
                style={{ pointerEvents: drawingButtonActive ? 'none' : 'auto', touchAction: drawingButtonActive ? 'none' : 'auto' }}
                onPointerDown={() => setIsDragging(true)}
                onPointerMove={(event) => {
                    if (!isDragging) {
                        return;
                    }
                    const element = ref.current;
                    if (!element) {
                        return;
                    };
                    const { top, left, width, height } = element.getBoundingClientRect();
                    const centerX = left + width / 2;
                    const centerY = top + height / 2;
                    if (previousX & previousY) {
                        const diffAngle = calculateAngle(
                            { x: previousX, y: previousY },
                            { x: event.clientX, y: event.clientY },
                            { x: centerX, y: centerY },
                        );
                        setAngle(angle + diffAngle);
                    }
                    setPreviousX(event.clientX);
                    setPreviousY(event.clientY);
                }}
                onPointerUp={() => {
                    setIsDragging(false);
                    setPreviousX(0);
                    setPreviousY(0);
                }}
            ></div>
            <div className="h-full w-full absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 z-20">
                <img
                    src={crp5RearDiskImage()}
                    className="h-full w-full object-contain pointer-events-none touch-none"
                    alt="crp5 rear disk"
                />
            </div>
        </div>
    );
};

const CanvasContainer = ({
    angle,
    drawingButtonActive,
    canvasHistory,
    setCanvasHistory,
    canvasUndo,
    setCanvasUndo,
    resetCanvas,
    setResetCanvas,
}: {
  angle: number
  drawingButtonActive: boolean
  canvasHistory: canvasHistoryType
  setCanvasHistory: (canvasHistory: object) => void
  canvasUndo: boolean
  setCanvasUndo: (value: boolean) => void
  resetCanvas: boolean
  setResetCanvas: (value: boolean) => void
}) => {

    return (
        <div
            className={
                'col-span-full row-span-full h-full w-auto relative hover:origin-center'
            }
            style={{ rotate: angle + 'deg' }}
        >
            <Canvas
                drawingButtonActive={drawingButtonActive}
                diskAngle={angle}
                canvasHistory={canvasHistory}
                setCanvasHistory={setCanvasHistory}
                canvasUndo={canvasUndo}
                setCanvasUndo={setCanvasUndo}
                resetCanvas={resetCanvas}
                setResetCanvas={setResetCanvas} />
        </div>
    );
};

const Card = ({
    position,
    drawingButtonActive,
}: {
position: number
drawingButtonActive: boolean
}) => {
    const [side, setSide] = useState(sides.low);

    return (
        <div
            className={
                'col-span-full row-span-full h-full w-auto relative hover:origin-center'
            }
            style={{ translate: '0.3%' + position * 1 + '%' }}
        >
            <div
                className="h-[100%] w-[55%] absolute left-1/2 top-[50%] -translate-y-1/2 -translate-x-1/2 cursor-pointer"
                style={{ pointerEvents: drawingButtonActive ? 'none' : 'auto', touchAction: drawingButtonActive ? 'none' : 'auto' }}
                onClick={() => {
                    if (side === sides.low) {
                        setSide(sides.high);
                    } else {
                        setSide(sides.low);
                    }
                }}
            ></div>
            <div className="h-full w-full absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2">
                {side === sides.low && <img
                    src={crp5SpeedScaleLowImage()}
                    className="h-full w-full object-contain pointer-events-none touch-none"
                    alt="crp5 speedscale"
                />}
                {side === sides.high && <img
                    src={crp5SpeedScaleHighImage()}
                    className="h-full w-full object-contain pointer-events-none touch-none"
                    alt="crp5 speedscale"
                />}
            </div>
        </div>
    );
};

const MoveInterceptor = ({
    position,
    setPosition,
}: {
position: number
setPosition: (angle: number) => void
}) => {
    const [isDragging, setIsDragging] = useState(false);
    const [previousY, setPreviousY] = useState(0);

    return (
        <div
            className={
                'col-span-full row-span-full h-full w-auto relative hover:origin-center'
            }
            style={{ translate: '0%' + position * 1 + '%' }}
        >
            <div
                className="h-[100%] w-[55%] absolute left-1/2 top-[50%] -translate-y-1/2 -translate-x-1/2 cursor-ns-resize select-none touch-auto pointer-events-auto"
                onPointerDown={() => setIsDragging(true)}
                onPointerMove={(event) => {
                    if (!isDragging) {
                        return;
                    }
                    if (previousY) {
                        let newPosition = position + (event.clientY - previousY) * 0.1;
                        if (newPosition > 41) {
                            newPosition = 41;
                        }
                        if (newPosition < -42) {
                            newPosition = -42;
                        }
                        setPosition(newPosition);
                    }
                    setPreviousY(event.clientY);
                }}
                onPointerUp={() => {
                    setIsDragging(false);
                    setPreviousY(0);
                }}
            ></div>
        </div>
    );
};

export const BackSide = ({
    scale,
    verticalOffset,
    horizontalOffset,
    diskAngle,
    setDiskAngle,
    cardPosition,
    setCardPosition,
    moveButtonActive,
    drawingButtonActive,
    canvasHistory,
    setCanvasHistory,
    canvasUndo,
    setCanvasUndo,
    resetCanvas,
    setResetCanvas,
}: {
  scale: number
  verticalOffset: number
  horizontalOffset: number
  diskAngle: number
  setDiskAngle: (angle: number) => void
  cardPosition: number
  setCardPosition: (position: number) => void
  moveButtonActive: boolean
  drawingButtonActive: boolean
  canvasHistory: canvasHistoryType
  setCanvasHistory: (canvasHistory: object) => void
  canvasUndo: boolean
  setCanvasUndo: (value: boolean) => void
  resetCanvas: boolean
  setResetCanvas: (value: boolean) => void
}) => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div
                className="inline-grid items-center justify-center h-full"
                style={{
                    scale: ((scale / 100) * 1.25).toString(),
                    translate: horizontalOffset * -1 + '%' + verticalOffset * 1 + '%',
                }}
            >
                <Card
                    position={cardPosition}
                    drawingButtonActive={drawingButtonActive}
                />
                <div className="col-span-full row-span-full h-full w-auto relative z-10">
                    <img
                        src={crp5RearFrameImage()}
                        className="h-full w-full  max-w-[600px] object-contain cursor-none"
                        alt="crp5 rear frame"
                    />
                    <div
                        className="h-[50%] w-[65%] absolute left-1/2 top-[50%] -translate-y-1/2 -translate-x-1/2 select-none touch-none pointer-events-none cursor-default"
                    ></div>
                </div>
                <CanvasContainer
                    angle={diskAngle}
                    drawingButtonActive={drawingButtonActive}
                    canvasHistory={canvasHistory}
                    setCanvasHistory={setCanvasHistory}
                    canvasUndo={canvasUndo}
                    setCanvasUndo={setCanvasUndo}
                    resetCanvas={resetCanvas}
                    setResetCanvas={setResetCanvas}
                />
                <Disk angle={diskAngle} setAngle={setDiskAngle} drawingButtonActive={drawingButtonActive} />
                {moveButtonActive && (
                    <MoveInterceptor position={cardPosition} setPosition={setCardPosition} />
                )}
            </div>
        </div>
    );
};
